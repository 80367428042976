import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import HttpApi from "i18next-http-backend";

// const resources = {
//     en: {
//       translation: {
//         app_name: "Grootbasket",
//       },
//     },
//     ar: {
//       translation: {
//         app_name: "جروتباسكت",
//       },
//     },
//   };
  
//   i18n
//     .use(initReactI18next)
//     .init({
//       resources,
//       lng: "en",
//       interpolation: {
//         escapeValue: false,
//       },
//       debug: true,
//     });

i18n

  .use(initReactI18next)

  .use(HttpApi)

  .init({

    lng: localStorage.getItem('app-lang') || "kk",   //default language

    fallbackLng: "kk", //when specified language translations not present 

//then fallbacklang translations loaded.

    debug: true,

    react: {
      useSuspense: false,
      wait: false,
    },


    // backend: {

    //   /* translation file path */

    // //   loadPath: "/locales/{{lng}}.json",

    // },
 

/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */


    // ns: ["translations"],

    // defaultNS: "translations",

    // keySeparator: false,

    interpolation: {

      escapeValue: false,

    },

    // react: {

    //   wait: true,

    // },

  });


export default i18n;