import './App.css';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";

// import messages_kz from "./locales/kz.json";
// import messages_ru from "./locales/ru.json";
// import messages_en from "./locales/en.json";

import AppRouter from "./router/Router";

// import { IntlProvider } from "react-intl";
// import './pages/MainPage';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

// const messages = {
//   ru: messages_ru,
//   kz: messages_kz,
//   en: messages_en,
// };

const THEME = createTheme({
  typography: {
    fontFamily: [
      "Euclid Circular B",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  
  return (
    <div>
      {/* <I18nextProvider i18n={i18n}> */}
        <ThemeProvider theme={THEME}>
          <AppRouter />
        </ThemeProvider>
      {/* </I18nextProvider> */}
    </div>
  );
}

export default App;
