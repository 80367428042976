import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CircularProgress, Box, Button, Fade, Grid } from "@mui/material";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from "react-i18next";


import { Background } from "../pages/Background";

const MainPage = lazy(() => import("../pages/MainPage"));
const StrengthData = lazy(() => import("../pages/StrengthData/Main"));
// const history = require("history").createBrowserHistory();
  
const talentsData = {
    'default' : {
        'talents' : [],
        'talentColor' : '#aeaeae',
        'talentChosenColor' : 'black',
        'talentChosenColorLess' : '#aeaeae',
    },
    'Executing' : {
        'talents' : ['Achiever', 'Discipline', 'Arranger', 'Focus', 'Belief', 'Responsibility', 
            'Consistency', 'Restorative', 'Deliberative'],
        'talentColor' : '#E7DBEB',
        'talentChosenColor' : '#6B187E',
        'talentChosenColorLess' : '#6b187e7d',
    },
    'Influencing' : {
        'talents' : ['Activator', 'Maximizer', 'Command', 'Self-Assurance', 'Communication', 
            'Significance', 'Competition', 'Woo'],
        'talentColor' : '#F6EBDB',
        'talentChosenColor' : '#CD7929',
        'talentChosenColorLess' : '#cd79296b',
    },
    'Relationship Building' : {
        'talents' : ['Adaptability', 'Includer', 'Connectedness', 'Individualization', 
            'Developer', 'Positivity', 'Empathy', 'Relator', 'Harmony'],
        'talentColor' : '#E3E7F4',
        'talentChosenColor' : '#4866C8',
        'talentChosenColorLess' : '#4866c8a1',
    },
    'Strategic Thinking' : {
        'talents' : ['Analytical', 'Input', 'Context', 'Intellection', 'Futuristic', 
            'Learner', 'Ideation', 'Strategic'],
        'talentColor' : '#DEEEE5',
        'talentChosenColor' : '#519560',
        'talentChosenColorLess' : '#519560a3',
    },
};

const AppRouter = () => {
    const { t, i18n } = useTranslation();
    const {currentLanguage, setCurrentLanguage} = useState('kk');
    const changeLanguageHandler = (e) =>
     {
        console.log('changing')
       i18n.changeLanguage(e.target.value)
       localStorage.setItem('app-lang', e.target.value)
     }



    return (
        // <Router history={history}>
        <Router>
            <Suspense
                fallback={
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                }
            >
                <div>
                <Background/>
                <Box sx={{ borderRadius: '24px'}} >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}
                    >
                        <Grid item style={{zIndex: 1}}>
                            <Box 
                            sx={{ borderRadius: '10px' }}
                            className='general-box'
                            // style={{}}
                            >
                                <Routes>
                                    <Route  path="/" element={<MainPage talentsData={talentsData} />} />
                                    <Route  path="/strengths" element={<StrengthData talentsData={talentsData} />} />
                                </Routes>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Box>
                
                {
                    i18n.language ? 
                    <Select
                                onChange={changeLanguageHandler}
                                value={i18n.language || window.localStorage.i18nextLng}
                                className="small-text zero-focus language-changer"
                            >
                                <MenuItem
                                    value={"kk"}>
                                        KZ
                                </MenuItem>
                                <MenuItem
                                    value={"ru"}>
                                        RU
                                </MenuItem>
                                <MenuItem
                                    value={"en"}>
                                        EN
                                </MenuItem>
                    </Select> : ''
                }
                    
                    {/* {
                        window.location.href.includes('/strengths') ?
                        <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'fixed', bottom: 20, right: 16 }}
                        icon={<RocketLaunchIcon />}
                        >
                            <SpeedDialAction
                                key={'WhatsApp'}
                                icon={<WhatsAppIcon />}
                                tooltipTitle={'WhatsApp'}
                                onClick={() => shareButton('WhatsApp')}
                            />
                            <SpeedDialAction
                                key={'Telegram'}
                                icon={<TelegramIcon />}
                                tooltipTitle={'Telegram'}
                                onClick={() => shareButton('Telegram')}
                            />
                            <SpeedDialAction
                                key={t('copy_link')}
                                icon={<ContentCopyIcon />}
                                tooltipTitle={t('copy_link')}
                                onClick={() => shareButton('copy_link')}
                            />
                        </SpeedDial> : ''
                    } */}
      
                    
            </div>
                
            </Suspense>
        </Router>
    );
};

export default AppRouter;